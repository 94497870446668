.row-header{
    margin:0px auto;
    padding:0px auto;
}

.row-content {
    margin:0px auto;
    padding: 50px 0px 50px 0px;
    border-bottom: 1px ridge;
    min-height:400px;
}

.footer{
    background-color: #000000;
    margin:0px auto;
    padding: 20px 0px 20px 0px;
}
.jumbotron {
    padding:70px 30px 70px 30px;
    margin:0px auto;
    background: #000000 ;
    color:#FFFAF0;
}

address{
    font-size:80%;
    margin:0px;
    color:#00FFFF;
}

.navbar-dark {
    background-color: #000000;
}

.page-enter {
    opacity: 0.01;
    transform: translateX(-100%);
}

.page-enter-active {
    opacity: 1;
    transform: translateX(0%);
    transition: all 300ms ease-in;
}

.page-exit {
    opacity: 1;
    transform: translateX(0%);
}

.page-exit-active {
    opacity: 0.01;
    transform: translateX(100%);
    transition: all 300ms ease-out;
}